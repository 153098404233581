.thank-you-container {
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-color: #f8f9fa;
}

.thank-you-content {
    max-width: 600px;
    width: 100%;
    text-align: center;
    padding: 2rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.thank-you-content h1 {
    color: #1a3c61;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-weight: bold;
}

.message-box {
    margin: 2rem 0;
    padding: 1.5rem;
    background-color: #f8f9fa;
    border-radius: 6px;
}

.message-box p {
    margin: 1rem 0;
    color: #4a5568;
    font-size: 1.1rem;
    line-height: 1.6;
}

.contact-info {
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid #e2e8f0;
}

.contact-info .phone {
    color: #1a3c61;
    font-weight: bold;
    font-size: 1.25rem;
}

.home-button {
    display: inline-block;
    padding: 0.75rem 2rem;
    background-color: #1a3c61;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-weight: 600;
    transition: background-color 0.3s ease;
    margin-top: 1rem;
}

.home-button:hover {
    background-color: #15325d;
    text-decoration: none;
    color: white;
}

@media (max-width: 640px) {
    .thank-you-content {
        padding: 1.5rem;
    }

    .thank-you-content h1 {
        font-size: 2rem;
    }

    .message-box {
        padding: 1rem;
    }
} 