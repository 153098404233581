.contact-page {
    padding: 2rem 1rem;
    max-width: 1200px;
    margin: 0 auto;
}

.page-header {
    text-align: center;
    margin-bottom: 3rem;
}

.page-header h1 {
    color: var(--sage);
    margin-bottom: 0.5rem;
}

.page-header p {
    color: var(--charcoal);
    font-size: 1.1rem;
}

.letter-card {
    background: white;
    padding: 3rem 4rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    margin: 2rem auto;
    max-width: 800px;
    line-height: 1.6;
}

.letter-card h1 {
    color: var(--dark);
    font-size: 2rem;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 3px solid var(--golden);
    text-align: center;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.form-group label {
    color: var(--dark);
    font-weight: 500;
    font-size: 1.1rem;
}

.form-group input,
.form-group textarea {
    padding: 0.75rem;
    border: 1px solid var(--sage);
    border-radius: 4px;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-color: var(--golden);
    box-shadow: 0 0 0 2px rgba(190, 144, 73, 0.1);
}

.submit-button {
    background-color: var(--golden);
    color: white;
    padding: 1rem 2rem;
    border: none;
    border-radius: 4px;
    font-size: 1.1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 1rem;
}

.submit-button:hover {
    background-color: var(--sage);
    transform: translateY(-2px);
}

.form-status {
    text-align: center;
    margin-top: 1rem;
    padding: 0.75rem;
    border-radius: 4px;
    background-color: var(--light-gray);
    color: var(--dark);
}

@media (max-width: 768px) {
    .letter-card {
        padding: 2rem;
        margin: 1rem;
    }
    
    .letter-card h1 {
        font-size: 1.75rem;
    }
    
    .form-group label {
        font-size: 1rem;
    }
    
    .submit-button {
        font-size: 1rem;
        padding: 0.75rem 1.5rem;
    }
} 