/* Hero Section */
.hero {
    position: relative;
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
    margin-top: -2rem;  /* Offset the page-wrapper padding */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;  /* Prevent image overflow */
    contain: layout size;  /* Hint to browser about containment */
}

.hero picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    contain: paint;  /* Optimize paint containment */
}

/* Hide all sources by default */
.hero picture source {
    display: none;
}

/* Ensure only one image shows at a time */
.hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    will-change: transform;  /* Hint about transform changes */
    transform: translateZ(0);  /* Force GPU acceleration */
    backface-visibility: hidden;
    -webkit-font-smoothing: antialiased;  /* Optimize text rendering */
}

/* Ensure hero content stays centered and readable */
.hero-content {
    position: relative;
    z-index: 1;
    text-align: center;
    padding: 2rem;
    contain: content;  /* Optimize content rendering */
}

.hero-content h1 {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    font-size: 4rem;
    margin-bottom: 20px;
    color: var(--white);
    letter-spacing: 2px;
    text-transform: uppercase;  /* Ensures text is uppercase */
    font-weight: 700;  /* Makes it bold like other headers */
}

.hero-slogan {
    font-family: "Snell Roundhand", "URW Chancery L", "Brush Script MT", cursive;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: var(--white);
}

.cta-button {
    padding: 12px 30px;
    font-size: 1.1rem;
    background-color: var(--golden); /* Changed from #007bff */
    color: var(--white);
    border: none;
    border-radius: 1px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.cta-button:hover {
    background-color: var(--sage); /* Add hover effect */
}

/* Text Boxes Section */
.text-boxes {
    padding: 60px 20px;
    margin-bottom: 20px;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}

.text-box {
    flex: 1;
    min-width: 300px;
    padding: 30px;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    border-radius: 8px;
}

.text-box h2 {
    margin-bottom: 15px;
}

.text-box-button {
    display: inline-block;
    margin-top: 20px;
    padding: 5px 10px;
    background-color: var(--golden); /* Changed from #007bff */
    color: var(--white);
    text-decoration: none;
    border-radius: 1px;
    transition: background-color 0.3s;
}

.text-box-button:hover {
    background-color: var(--sage); /* Add hover effect */
}

.hero-text p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: var(--white);
}

.hero-button {
    margin-top: 2rem;
}

/* Google Reviews Section */
.google-reviews-section {
    margin-top: -40px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.google-reviews-section h2 {
    color: #666;  /* Grey text color */
    margin-bottom: 15px;
}

.google-reviews-section p {
    color: #666;  /* Grey text color */
}

/* Keep existing text boxes styling */
.text-boxes {
    padding: 60px 20px;
    margin-bottom: 20px;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}

/* Add responsive text sizes */
@media screen and (max-width: 767px) {
    .hero-content h1 {
        font-size: 2.5rem;
    }
    .hero-slogan {
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .hero-content h1 {
        font-size: 3.5rem;
    }
    .hero-slogan {
        font-size: 2rem;
    }
}

@media screen and (min-width: 1024px) {
    .hero-content h1 {
        font-size: 4rem;
    }
    .hero-slogan {
        font-size: 2.5rem;
    }
} 