.sensene-page {
    min-height: calc(100vh - 60px);
    background-color: var(--white);
    padding: 40px 0;
}

.content-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;
}

.letter-card {
    background: white;
    padding: 3rem 4rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    margin: 2rem auto;
    max-width: 800px;
    line-height: 1.6;
}

.letter-card h1 {
    color: var(--dark);
    font-size: 2rem;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 3px solid var(--golden);
    text-align: center;
}

.letter-card p {
    margin-bottom: 1.5rem;
    color: var(--dark);
    font-size: 1.1rem;
}

.letter-card a {
    color: var(--golden);
    text-decoration: none;
    transition: color 0.3s ease;
}

.letter-card a:hover {
    color: var(--sage);
    text-decoration: underline;
}

.signature {
    margin-top: 3rem;
    font-style: italic;
}

/* Responsive Design */
@media (max-width: 768px) {
    .letter-card {
        padding: 2rem;
        margin: 1rem;
    }
    
    .letter-card p {
        font-size: 1rem;
    }

    .letter-card h1 {
        font-size: 1.75rem;
    }
} 