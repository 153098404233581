/* Brand Color Variables (matching your existing theme) */
:root {
    --golden: rgb(190, 144, 73);
    --sage: rgb(135, 152, 143);
    --white: #ffffff;
    --light-gray: #f8f9fa;
    --dark: #2c3e50;
}

.about-page {
    min-height: calc(100vh - 60px); /* Subtract navbar height */
    background-color: var(--light-gray);
    padding: 3rem 1rem;
}

.about-content {
    max-width: 1000px;
    margin: 0 auto;
    background-color: var(--white);
    padding: 3rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.about-content h1 {
    color: var(--dark);
    font-size: 2.5rem;
    margin-bottom: 2rem;
    border-bottom: 3px solid var(--golden);
    padding-bottom: 1rem;
}

.about-section {
    margin: 2.5rem 0;
}

.about-section h2 {
    color: var(--dark);
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    position: relative;
    padding-left: 1rem;
}

.about-section h2::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: var(--sage);
    border-radius: 2px;
}

.about-section p {
    line-height: 1.8;
    color: var(--dark);
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    .about-page {
        padding: 2rem 1rem;
    }

    .about-content {
        padding: 2rem;
    }

    .about-content h1 {
        font-size: 2rem;
    }

    .about-section h2 {
        font-size: 1.5rem;
    }

    .about-section p {
        font-size: 1rem;
    }
}

.letter-card {
    background: white;
    padding: 3rem 4rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    margin: 2rem auto;
    max-width: 800px;
    line-height: 1.6;
}

.letter-card h1 {
    color: var(--dark);
    font-size: 2rem;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 3px solid var(--golden);
    text-align: center;
}

.letter-card h3 {
    color: var(--dark);
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding-left: 1rem;
    border-left: 4px solid var(--sage);
}

.letter-card p {
    margin-bottom: 1.5rem;
    color: var(--dark);
    font-size: 1.1rem;
}

@media (max-width: 768px) {
    .letter-card {
        padding: 2rem;
        margin: 1rem;
    }
    
    .letter-card p {
        font-size: 1rem;
    }

    .letter-card h1 {
        font-size: 1.75rem;
    }

    .letter-card h3 {
        font-size: 1.25rem;
    }
} 