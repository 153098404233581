:root {
  --golden: rgb(190, 144, 73);
  --sage: rgb(135, 152, 143);
  --white: #ffffff;
  --light-gray: #f8f9fa;
  --dark: #2c3e50;
}

.store-page {
  display: flex;
  min-height: calc(100vh - 60px); /* Subtract navbar height */
}

.store-image {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.store-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: sticky;
  top: 60px; /* Navbar height */
}

.store-content {
  flex: 1;
  padding: 3rem;
  background-color: var(--white);
}

.store-content h1 {
  color: var(--dark);
  font-size: 2.5rem;
  margin-bottom: 2rem;
  border-bottom: 3px solid var(--golden);
  padding-bottom: 1rem;
}

.store-details {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.contact-info {
  text-align: left;
  padding-left: 0rem;
  margin-left: 0;
}

.contact-info p {
  text-align: left;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
}

.contact-info i {
  width: 25px;
  margin-right: 1rem;
  text-align: center;
  flex-shrink: 0;
}

.hours {
  margin-top: 1rem;
  padding-left: 0rem;
}

.hours p {
  text-align: left;
  margin: 0.25rem 0;
  display: flex;
  align-items: center;
}

.hours i {
  width: 25px;
  margin-right: 1rem;
  text-align: center;
  flex-shrink: 0;
}

.store-description {
  line-height: 1.6;
  color: var(--dark);
  padding-left: 2rem;
}

.specialties, .services {
  margin-top: 2rem;
}

.specialties h2, .services h2 {
  color: var(--dark);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.specialties ul, .services ul {
  list-style: none;
  padding: 0;
}

.specialties li, .services li {
  margin: 0.5rem 0;
  color: var(--dark);
}

.specialties i {
  color: var(--golden);
  margin-right: 10px;
}

.services i {
  color: var(--sage);
  margin-right: 10px;
}

.store-cta {
  margin-top: 3rem;
  text-align: center;
}

.direction-button {
  display: inline-block;
  padding: 1rem 2rem;
  background-color: var(--golden);
  color: var(--white);
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.direction-button:hover {
  background-color: var(--sage);
}

.direction-button i {
  margin-right: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .store-page {
    flex-direction: column;
  }

  .store-image {
    height: 400px;
  }

  .store-image img {
    position: relative;
    top: 0;
  }

  .store-content {
    padding: 2rem;
  }
} 