.not-found-container {
    padding: 4rem 2rem;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.not-found-content-box {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    width: 90%;
    max-width: 600px;
}

.not-found-content-box h1 {
    color: var(--primary);
    margin-bottom: 1rem;
    font-size: 2.5rem;
}

.not-found-links {
    margin-top: 2rem;
    text-align: left;
}

.not-found-links ul {
    list-style: none;
    padding: 0;
    margin-top: 1rem;
}

.not-found-links li {
    margin: 0.5rem 0;
}

.not-found-links a {
    color: var(--secondary);
    text-decoration: none;
    font-weight: 600;
}

.not-found-links a:hover {
    color: var(--secondary-dark);
    text-decoration: underline;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .not-found-container {
        padding: 2rem 1rem;
    }
    
    .not-found-content-box {
        padding: 1.5rem;
    }
    
    .not-found-content-box h1 {
        font-size: 2rem;
    }
} 