.footer {
  background-color: rgba(0, 0, 0, 0.9);  /* 90% black */
  color: var(--white);
  height: 230px;
  padding: 20px 0;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  height: 100%;
}

.footer-column {
  flex: 1;
  padding: 0 15px;
}

.footer-column h3 {
  color: var(--golden);
  font-size: 1.2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-column ul li {
  margin-bottom: 0.5rem;
}

.footer-column ul li a {
  color: var(--white);
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.footer-column ul li a:hover {
  color: var(--golden);
} 