:root {
  --golden: rgb(190, 144, 73);
  --sage: rgb(135, 152, 143);
  --white: #ffffff;
  --light-gray: #f8f9fa;
  --dark: #2c3e50;
  --shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  --transition: all 0.3s ease;
}

.corporate {
  background: none;
  min-height: 100vh;
}

.page-header {
  background-color: var(--white);
  padding: 1.5rem 0;
  text-align: center;
  color: var(--dark);
  border-radius: 8px;
  box-shadow: var(--shadow);
  margin-bottom: 2rem;
}

.page-header h1 {
  font-size: 2rem;
  color: var(--dark);
  font-weight: 600;
  margin: 0;
}

.page-header p {
  color: var(--sage);
  margin-top: 0.5rem;
}

.container {
  background: none;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* Benefits Section */
.corporate-benefits,
.industries-section {
  margin-bottom: 2rem;
}

.corporate-benefits h2,
.industries-section h2 {
  text-align: center;
  color: var(--dark);
  margin-bottom: 1rem;
}

.benefits-grid,
.industries-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  padding: 1.5rem 0;
}

.benefit-card,
.industry-card {
  background: var(--white);
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
  box-shadow: var(--shadow);
  transition: transform var(--transition);
}

.benefit-card:hover,
.industry-card:hover {
  transform: translateY(-5px);
}

.benefit-card i,
.industry-card i {
  font-size: 2.5rem;
  color: var(--sage);
  margin-bottom: 2rem;
}

.benefit-card h3,
.industry-card h3 {
  margin-bottom: 1rem;
  color: var(--dark);
}

.benefit-card p,
.industry-card p {
  color: var(--sage);
}

/* CTA Section */
.corporate-cta {
  text-align: center;
  background-color: var(--white);
  padding: 2rem;
  margin-top: 2rem;
}

.corporate-cta h2 {
  color: var(--dark);
  margin-bottom: 1rem;
}

.corporate-cta p {
  color: var(--sage);
  margin-bottom: 2rem;
}

.cta-button {
  display: inline-block;
  padding: 0.5rem 1.5rem;
  background-color: var(--golden);
  color: var(--white);
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: var(--sage);
}

/* Responsive Design */
@media (max-width: 1200px) {
  .benefits-grid,
  .industries-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .benefits-grid,
  .industries-grid {
    grid-template-columns: 1fr;
  }
} 