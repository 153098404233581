:root {
    --primary: #617165;  /* Darker green */
    --primary-dark: #4B574E;  /* Even darker for hover */
    --secondary: #8B6830;  /* Darker gold */
    --secondary-dark: #735628;  /* Even darker for hover */
    --text-light: #ffffff;
    --text-dark: #333333;
    
    /* Add font variables */
    --main-font: 'Afacad', sans-serif;
    --script-font: 'Estonia', cursive;
}

/* Reset default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: var(--main-font);
    line-height: 1.6;
}

/* Navigation */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 5%;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.nav-links {
    display: flex;
    list-style: none;
}

.nav-links li {
    margin-left: 2rem;
}

.nav-links a {
    text-decoration: none;
    color: var(--text-dark);  /* Using defined dark text color */
    font-weight: 600;  /* Make text slightly bolder */
}

.book-now a {
    background-color: var(--secondary);
    color: var(--text-light);
    padding: 0.5rem 1rem;
    border-radius: 5px;
    font-weight: 600;  /* Make text slightly bolder */
}

.book-now a:hover {
    background-color: var(--secondary-dark);
}

/* Hero Section */
.hero {
    height: 80vh;
    background-image: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('./assets/images/headerDesktop.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
}

/* Example usage of Estonia for special headings or accents */
.script-accent {
    font-family: var(--script-font);
}

/* ... continuing with the rest of your styles ... */

.page-wrapper {
    padding: 2rem 1rem;
    position: relative;
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
}

/* Mobile background pattern */
.page-wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('./assets/images/greenpatternMobile.jpg') repeat center center;
    background-size: auto 50%;
    opacity: 0.2;
    z-index: -1;
}

/* Tablet and desktop background pattern */
@media screen and (min-width: 768px) {
    .page-wrapper::before {
        background: url('./assets/images/greenpattern2.png') repeat center center;
        background-size: auto 50%;
    }
}

.page-content {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}
