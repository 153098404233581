:root {
    --golden: rgb(190, 144, 73);    /* Crown's golden yellow */
    --sage: rgb(135, 152, 143);     /* Crown's sage green */
    --white: #ffffff;
    --light-gray: #f8f9fa;
    --dark: #2c3e50;
    --golden-light: rgba(190, 144, 73, 0.1);  /* For subtle backgrounds */
    --sage-light: rgba(135, 152, 143, 0.1);   /* For subtle backgrounds */
    --shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    --transition: all 0.3s ease;
  }

  /* Header */
.page-header {
    background-color: var(--white);
    padding: 1.5rem 0;
    text-align: center;
    color: var(--dark);
    border-radius: 8px;  /* Keep rounded corners */
    box-shadow: var(--shadow);  /* Keep drop shadow */
    margin-bottom: 2rem;  /* Add some space below the header */
  }
  
  .page-header h1 {
    font-size: 2rem;
    color: var(--dark);
    font-weight: 600;
    margin: 0;
  }
  
  .page-header p {
    color: var(--sage);
    margin-top: 0.0rem;
  }
  
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  padding: 2rem 0;
}

.service-card {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.service-card:hover {
  transform: translateY(-5px);
}

.service-card i {
  font-size: 2.5rem;
  color: var(--primary);
  margin-bottom: 1rem;
}

.service-card h3 {
  margin-bottom: 1rem;
  color: var(--text-dark);
}

.service-card p {
  color: var(--text-dark);
  margin-bottom: 1.5rem;
}

.service-button {
  display: inline-block;
  padding: 0.5rem 1.5rem;
  background-color: var(--primary);
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.service-button:hover {
  background-color: var(--primary-dark);
}

.additional-services {
  background-color: #f5f5f5;
  padding: 3rem 0;
}

.additional-services h2 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--text-dark);
}

/* Responsive Design */
@media (max-width: 1200px) {
  .services-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .services-grid {
    grid-template-columns: 1fr;
  }
}

.services-section {
  padding-top: 1rem;
}

/* Reset any potential inherited styles */
.services {
  margin: 0;
  padding: 0;
}

.page-header {
  text-align: center;
  padding: 1rem 0;
  background-color: var(--primary);
  color: white;
  margin: 0;  /* Explicitly set margin to 0 */
}

/* Debug outline to see spacing */
.services * {
  outline: 1px solid transparent; /* Temporarily enable to see box model */
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90vh;  /* Limit height to 90% of viewport height */
  overflow-y: auto;  /* Enable scrolling if content is too long */
  position: relative;
  margin: 20px;      /* Add some margin around the modal */
}

.modal-header {
  position: sticky;
  top: 0;
  background: white;
  padding-bottom: 10px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h2 {
  color: var(--dark);
  margin: 0;
}

.close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
}

.modal-body {
  color: var(--dark);
}

.modal-intro {
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.pricing-info {
  background-color: var(--light-gray);
  padding: 1.5rem;
  border-radius: 4px;
  margin-bottom: 1.5rem;
}

.pricing-info h3 {
  color: var(--golden);
  margin-bottom: 1rem;
}

.pricing-info ul {
  list-style: none;
  padding: 0;
}

.pricing-info li {
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;
}

.pricing-info li:before {
  content: "•";
  color: var(--golden);
  position: absolute;
  left: 0;
}

.special-note {
  border-left: 3px solid var(--sage);
  padding-left: 1rem;
  margin-top: 1.5rem;
}

.special-note p {
  margin: 0.5rem 0;
}

.learn-more-btn {
  background-color: var(--golden);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  border-radius: 0;  /* Remove any border radius */
  text-transform: none;  /* Remove text transform */
  letter-spacing: normal;  /* Reset letter spacing */
}

.learn-more-btn:hover {
  background-color: var(--sage);  /* Hover color changes to sage */
  color: white;
}

/* Mobile-specific adjustments */
@media screen and (max-width: 768px) {
  .modal-content {
    width: 95%;
    margin: 10px;
    max-height: 80vh;  /* Slightly smaller on mobile */
  }
  
  .close-button {
    padding: 10px 15px;  /* Larger tap target on mobile */
  }
} 