.delivery-page {
    padding: 2rem 1rem;
    position: relative;
    width: 100vw;  /* Full viewport width */
    margin-left: calc(-50vw + 50%);  /* Center the full-width container */
    margin-right: calc(-50vw + 50%);
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem; /* Add horizontal padding */
}

/* Add the pattern as a pseudo-element with reduced opacity */
.delivery-page::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../assets/images/greenpattern2.png') repeat center center;
    background-size: auto 50%;
    opacity: 0.0;  /* Adjust this value between 0 and 1 */
    z-index: -1;
}

/* Keep content constrained */
.content-container {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

.page-header {
    background-color: var(--white);
    padding: 1.5rem 0;
    text-align: center;
    color: var(--dark);
    border-radius: 8px;
    box-shadow: var(--shadow);
    margin-bottom: 2rem;
}

.page-header h1 {
    font-size: 2rem;
    color: var(--dark);
    font-weight: 600;
    margin: 0;
}

.page-header p {
    color: var(--sage);
    margin-top: 0.5rem;  /* Add some space between h1 and p */
}

.content-container {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    background: rgba(255, 255, 255, 0.95);
    border-radius: 12px;
    padding: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Delivery Finder Section */
.delivery-finder {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 0 auto;
}

/* Benefits Section */
.benefits-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    padding: 1.5rem 0;
    margin-top: 2rem;
}

.benefit-card {
    background-color: white;
    padding: 1.5rem;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.benefit-card:hover {
    transform: translateY(-5px);
}

.benefit-card i {
    font-size: 2.5rem;
    color: var(--sage);
    margin-bottom: 1rem;
}

.benefit-card h3 {
    color: var(--charcoal);
    margin-bottom: 1rem;
}

/* How It Works Section */
.steps-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding: 1.5rem 0;
    margin-top: 2rem;
}

.step {
    background-color: white;
    padding: 1.5rem;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.step-number {
    background-color: var(--sage);
    color: white;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-bottom: 1rem;
}

.step h3 {
    color: var(--charcoal);
    margin-bottom: 1rem;
}

/* Service Features Section */
.features-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-top: 2rem;
    list-style: none;
    padding: 0;
}

.features-list li {
    background-color: white;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    gap: 1rem;
}

.features-list li::before {
    content: '✓';
    color: var(--sage);
    font-weight: bold;
}

/* Contact Info Section */
.contact-info {
    text-align: center;
    padding: 2rem;
    margin: 0 auto;
}

.contact-info h2 {
    color: var(--sage);
    margin-bottom: 1rem;
}

.contact-info a {
    color: var(--sage);
    text-decoration: none;
    font-weight: 500;
}

.contact-info a:hover {
    text-decoration: underline;
}

/* Section Headers */
section h2 {
    color: var(--sage);
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    .delivery-page {
        padding: 1rem;
    }

    .page-header {
        padding: 1rem 0;
    }

    .page-header h1 {
        font-size: 2rem;
    }

    .benefits-grid,
    .steps-container,
    .features-list {
        grid-template-columns: 1fr;
    }

    .step,
    .benefit-card {
        padding: 1.5rem;
    }
}

/* Address Lookup Form */
.delivery-finder {
    padding: 1rem;
}

.delivery-finder h2 {
    color: var(--sage);
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
}

.address-lookup {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.status-message {
    color: var(--charcoal);
    text-align: center;
    margin-bottom: 1rem;
    font-size: 0.9rem;
}

.input-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-height: 90px; /* Prevent layout shift when button disappears */
}

.address-input {
    width: 100%;
    padding: 0.75rem;
    border: 2px solid var(--light-gray);
    border-radius: 0;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

.address-input:focus {
    border-color: var(--golden);
    outline: none;
}

.check-button {
    background-color: #FFB81C !important;
    color: black !important;
    font-family: inherit !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    padding: 0.75rem 1.5rem !important;
    border: none !important;
    border-radius: 4px !important;
    cursor: pointer !important;
    transition: background-color 0.2s ease !important;
    width: 100% !important;
    margin-top: 0.5rem !important;
}

.check-button:hover {
    background-color: #ffa500 !important;
}

.check-button:focus {
    outline: none !important;
    box-shadow: 0 0 0 2px rgba(255, 184, 28, 0.5) !important;
}

.check-button:disabled {
    background-color: #ccc !important;
    cursor: not-allowed !important;
}

.check-button.hiding {
    opacity: 0;
    transform: translateY(10px);
    pointer-events: none;
}

/* Delivery Info Result */
.delivery-info {
    margin-top: 1.5rem;
    padding: 1rem;
    background-color: var(--light-gray);
    border-radius: 4px;
    text-align: center;
}

.delivery-info h3 {
    color: var(--sage);
    margin-bottom: 0.5rem;
}

.delivery-info p {
    color: var(--charcoal);
    font-size: 1.1rem;
}

/* Google Places Autocomplete Customization */
.pac-container {
    border-radius: 4px;
    margin-top: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.pac-item {
    padding: 0.75rem;
    cursor: pointer;
}

.pac-item:hover {
    background-color: var(--light-gray);
}

.pac-item-selected {
    background-color: var(--light-gray);
}

/* Hide the "Ready" status message */
.status-message:empty,
.status-message:contains("Ready") {
    display: none;
}

/* Signup Section */
.signup-section {
    background-color: #ffffff;
    padding: 2rem 1rem 0;  /* Added top padding */
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    max-width: 600px; /* Keep the signup form centered and not too wide */
}

.signup-section h2 {
    color: var(--sage);
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

.signup-form {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.08);
    max-width: 600px;
    margin: 0 auto;
}

.signup-form .input-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.email-input {
    width: 100%;
    padding: 0.75rem;
    border: 2px solid var(--light-gray);
    border-radius: 0;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

.email-input:focus {
    border-color: var(--golden);
    outline: none;
}

.signup-button {
    background-color: var(--golden);
    color: white;
    padding: 1rem;
    border: none;
    border-radius: 0;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
}

.signup-button:hover {
    background-color: var(--dark-golden);
}

.signup-button:disabled {
    background-color: var(--light-gray);
    cursor: not-allowed;
}

/* Responsive Design */
@media (max-width: 768px) {
    .signup-section {
        padding: 2rem 1rem;
    }

    .signup-section h2 {
        font-size: 2rem;
    }
}

/* Progressive Form Styling */
.email-signup-group {
    margin-top: 2rem;
    opacity: 0;
    transform: translateY(20px);
    animation: slideUp 0.5s ease forwards;
}

@keyframes slideUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.delivery-info {
    margin: 1.5rem 0;
    padding: 1rem;
    background-color: var(--light-gray);
    opacity: 0;
    transform: translateY(20px);
    animation: slideUp 0.5s ease forwards;
}

/* Update existing styles */
.signup-form .input-group {
    margin-bottom: 0; /* Remove bottom margin since we're using dynamic spacing */
}

/* Add these new styles */
.service-info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-top: 2rem;
}

.service-card {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    text-align: center;
}

.service-card i {
    font-size: 2.5rem;
    color: var(--sage);
    margin-bottom: 1rem;
}

.team-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin: 1rem 0;
    width: 100%;
}

.team-member {
    background: white;
    padding: 0.75rem 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.team-member i {
    font-size: 1.25rem;
    color: var(--sage);
    margin-bottom: 0.25rem;
}

.team-member h3 {
    font-size: 1.1rem;
    margin-bottom: 0.2rem;
}

.team-member p {
    font-size: 0.85rem;
    margin: 0.2rem 0;
    line-height: 1.2;
}

.team-member a {
    display: block;
    color: var(--sage);
    text-decoration: none;
    margin: 0.2rem 0;
    font-weight: 500;
    font-size: 0.85rem;
}

.team-member a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .service-info,
    .team-container {
        grid-template-columns: 1fr;
    }
    
    .service-card,
    .team-member {
        padding: 1.5rem;
    }
}

.privacy-message {
    color: var(--charcoal);
    font-size: 1.1rem;
    line-height: 1.6;
    margin: 1.5rem auto 2rem;
    max-width: 800px;
    text-align: center;
}

.delivery-info .team-container {
    margin-top: 2rem;
    opacity: 0;
    transform: translateY(10px);
    animation: slideUp 0.5s ease forwards 0.3s;
}

.signup-form-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1.5rem;
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.signup-input {
    padding: 0.75rem 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    width: 100%;
}

.signup-button {
    background-color: var(--gold);
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
}

.signup-button:hover {
    background-color: var(--dark-gold);
}

.signup-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* More specific selectors for the Check Address button */
.signup-form .input-group .check-button {
    background-color: var(--gold) !important;
    color: white !important;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
}

.signup-form .input-group .check-button:hover {
    background-color: var(--dark-gold) !important;
}

.signup-form .input-group .check-button:disabled {
    background-color: #ccc !important;
    cursor: not-allowed;
}

/* More specific selectors for the Sign Up button */
.signup-form .signup-form-group .signup-button {
    background-color: var(--gold) !important;
    color: white !important;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
}

.signup-form .signup-form-group .signup-button:hover {
    background-color: var(--dark-gold) !important;
}

.signup-form .signup-form-group .signup-button:disabled {
    background-color: #ccc !important;
    cursor: not-allowed;
}

/* Existing button styles with additional specificity */
.signup-form .input-group button[type="button"].check-button {
    background-color: var(--golden) !important;
    color: white !important;
}

.signup-form .signup-form-group button[type="submit"].signup-button {
    background-color: var(--golden) !important;
    color: rgb(255, 255, 255) !important;
}

/* Super specific override for the Check Address button */
.delivery-finder .input-group button[type="button"],
.delivery-finder .address-lookup button[type="button"],
.check-button,
button[type="button"].check-button,
.delivery-finder button[type="button"],
[class*="delivery"] button[type="button"] {
    background-color: var(--secondary) !important;
    color: var(--text-light) !important;
    font-family: var(--main-font) !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    padding: 0.75rem 1.5rem !important;
    border: none !important;
    border-radius: 4px !important;
    cursor: pointer !important;
    transition: background-color 0.2s ease !important;
    width: 100% !important;
    margin-top: 0.5rem !important;
}

/* Hover state */
.delivery-finder .input-group button[type="button"]:hover,
.delivery-finder .address-lookup button[type="button"]:hover,
.check-button:hover,
button[type="button"].check-button:hover,
.delivery-finder button[type="button"]:hover,
[class*="delivery"] button[type="button"]:hover {
    background-color: var(--secondary-dark) !important;
}

/* Ensure disabled state maintains consistency */
.delivery-finder .input-group button[type="button"]:disabled,
.delivery-finder .address-lookup button[type="button"]:disabled,
.check-button:disabled {
    background-color: #ccc !important;
    cursor: not-allowed;
}

/* New unique class name for the address check button */
.crown-address-check-btn {
    background-color: var(--secondary) !important;
    color: var(--text-light) !important;
    font-family: var(--main-font) !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    padding: 0.75rem 1.5rem !important;
    border: none !important;
    border-radius: 4px !important;
    cursor: pointer !important;
    transition: background-color 0.2s ease !important;
    width: 100% !important;
    margin-top: 0.5rem !important;
}

.crown-address-check-btn:hover {
    background-color: var(--secondary-dark) !important;
}

.crown-address-check-btn:focus {
    outline: none !important;
    box-shadow: 0 0 0 2px rgba(255, 184, 28, 0.5) !important;
}

.crown-address-check-btn:disabled {
    background-color: #ccc !important;
    cursor: not-allowed !important;
}

/* Add highlight box styling from Corporate page */
.highlight-box {
    background-color: var(--light-gray);
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
}

/* Update page wrapper styling */
.page-wrapper {
    padding: 2rem 1rem;
    position: relative;
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
}

.page-wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: auto 50%;
    opacity: 0.0;
    z-index: -1;
}

.page-content {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
} 