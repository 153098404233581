/* Brand Color Variables */
:root {
  --golden: rgb(190, 144, 73);    /* Crown's golden yellow */
  --sage: rgb(135, 152, 143);     /* Crown's sage green */
  --white: #ffffff;
  --light-gray: #f8f9fa;
  --dark: #2c3e50;
  --golden-light: rgba(190, 144, 73, 0.1);  /* For subtle backgrounds */
  --sage-light: rgba(135, 152, 143, 0.1);   /* For subtle backgrounds */
  --shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  --transition: all 0.3s ease;
}

/* Base Styles */
.locations {
  background-color: transparent;
  min-height: 100vh;
}

/* Header */
.page-header {
  background-color: var(--white);
  padding: 1.5rem 0;  /* Match Services page padding */
  text-align: center;
  color: var(--dark);
  border-radius: 8px;  /* Add rounded corners like Services */
  box-shadow: var(--shadow);  /* Add shadow like Services */
  margin-bottom: 2rem;  /* Add spacing below header */
}

.page-header h1 {
  font-size: 2rem;  /* Match Services page font size */
  color: var(--dark);
  font-weight: 600;  /* Match Services page font weight */
  margin: 0;
}

.page-header p {
  color: var(--sage);
  margin-top: 0.5rem;  /* Add some space between h1 and p */
}

/* Container for both views */
.locations-section {
  background: none;
  padding: 1rem;
}

/* Toggle Buttons */
.view-toggle {
  display: flex;
  justify-content: flex-end;
  margin: 0.0rem;
  padding-right: 2rem;
  gap: 1rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.toggle-button {
  padding: 0.5rem 1.5rem;
  border: 2px solid var(--golden);
  background: transparent;
  color: var(--golden);
  cursor: pointer;
  transition: all 0.3s ease;
}

.toggle-button.active {
  background: var(--golden);
  color: white;
}

.toggle-button:hover {
  background: var(--golden);
  color: white;
}

/* Grid View Specific */
.locations-grid {
  display: none;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.locations-grid.view-active {
  display: grid;
}

.location-card {
  position: relative;
  padding: 2rem;
  overflow: hidden;
  color: var(--white);
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: all 0.3s ease;
}

.location-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);  /* 50% black overlay by default */
  transition: background 0.3s ease;
  z-index: 1;
}

.location-card:hover::before {
  background: rgba(0, 0, 0, 0.75);  /* 75% black overlay on hover */
}

/* Ensure content stays above overlay */
.location-card h3,
.location-details,
.location-actions {
  position: relative;
  z-index: 2;
}

.location-card h3 {
  color: var(--golden);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.location-details {
  margin-bottom: 1.5rem;
}

.location-details p {
  display: flex;
  align-items: flex-start;
  margin: 0.5rem 0;
  line-height: 1.4;
}

.location-details i {
  color: var(--golden);
  width: 20px;
  margin-right: 12px;
  flex-shrink: 0;
  margin-top: 3px;
}

/* Update address container styles */
.address-container {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.street-address {
  display: block;
}

.city-state {
  display: block;
  color: var(--white);
  opacity: 0.9;
}

/* Remove the previous address-line and address-city styles */

.location-button {
  display: inline-block;
  padding: 0.5rem 1.5rem;
  background-color: var(--golden);
  color: var(--white);
  text-decoration: none;
  transition: var(--transition);
  border: 1px solid var(--golden);
}

.location-button:hover {
  background-color: transparent;
  color: var(--golden);
}

/* Map View Specific */
.map-view {
  display: none;
  overflow: hidden;
}

.map-view.view-active {
  display: block;
  width: 100%;
  padding: 1rem 0;
}

.map-view iframe {
  width: 100%;
  height: calc(80vh + 150px);
  border: none;
  margin-top: -200px;
  display: block;
  position: relative;
  z-index: 1;
}

/* Responsive Grid */
@media (max-width: 1200px) {
  .locations-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .locations-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .locations-grid {
    grid-template-columns: 1fr;
  }
  
  .map-view iframe {
    height: 70vh;  /* Slightly smaller on mobile */
  }
}

/* Center the store page button */
.location-actions {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
} 