/* Basic navbar styling example */
.navbar {
    background-color: rgba(0, 0, 0, 0.90) !important;  /* Force override with !important */
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 999;
    width: 100%;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    padding: 0 0px 0 0px;
    background-color: transparent;
}

.nav-menu {
    display: flex;
    align-items: center;
    list-style: none;
    height: 60px;
    margin-right: 0;
}

/* Adjust logo and text sizes if needed */
.navbar-logo {
    color: var(--white);  /* White text */
    text-decoration: none;
    font-size: 1.5rem;
    padding: 0.5rem 0;
}

.navbar-logo-img {
    height: 40px;  /* Adjust this value to fit your navbar height */
    width: auto;
    object-fit: contain;
}

.nav-link {
    color: var(--white);
    text-decoration: none;
    padding: 0.3rem 0.8rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 0.8rem;
    transition: color 0.3s ease;
}

.nav-link:hover {
    color: var(--secondary);  /* Changed from --golden */
}

.nav-item {
    height: 50px;
    display: flex;
    align-items: center;
}

/* Style the menu icon (hamburger) */
.menu-icon {
    display: none;  /* Hidden by default on desktop */
    color: var(--white);
    font-size: 1.8rem;
    cursor: pointer;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
    .menu-icon {
        display: block;
        position: absolute;
        right: 20px;
        transform: translate(0, -50%);
        top: 50%;
        cursor: pointer;
    }

    .nav-menu {
        display: none;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 60px;
        left: 0;
        margin: 0;
        padding: 0;
    }

    .nav-menu.active {
        display: flex;
        opacity: 1;
    }

    .nav-item {
        height: 46px;
        margin-top: -1px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(0, 0, 0);
        padding: 0;
    }

    .nav-link {
        text-align: center;
        width: 100%;
        padding: 1rem 0;
    }
}

/* Add these styles to your existing Navbar.css */
.nav-link.cta-button {
    background-color: var(--secondary);  /* Changed from --golden */
    color: var(--text-light);
    padding: 0.1rem 0.4rem;
    border-radius: 4px;  /* Match other buttons */
    transition: background-color 0.3s ease;
    font-size: 0.9rem;
}

.nav-link.cta-button:hover {
    background-color: var(--secondary-dark);  /* Changed from --dark-golden */
    color: var(--text-light);
}

/* Update mobile styles */
@media screen and (max-width: 960px) {
    .nav-link.cta-button {
        margin: 0.4rem 0.8rem;
        text-align: center;
    }
}